import React from 'react';

import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer';

import './styles.css';

import adressIcon from '../../assets/icons/adress.png';
import emailIcon from '../../assets/icons/mail.png';
import phoneIcon from '../../assets/icons/phone.png';
import whatsappIcon from '../../assets/icons/whatsapp.png';

export default function Contact() {
  return (
    <>
      <Navbar />
      <main>
        <div id="contact-title-container">
          <h1>CONTATO</h1>
          <h3>Entre em contato agora mesmo e contrate nossos serviços</h3>
        </div>

        <div id="container">
          <div id="contact-container">
            <div className="row">
              <div className="contact-item">
                <div className="contact-icon">
                  <img src={adressIcon} alt="Icone de e-mail" />
                </div>

                <div className="contact-right-side">
                  <div className="contact-title">
                    <h3>Endereço</h3>
                  </div>

                  <div className="contact-info">
                    <p>
                      Avenida João Batista Vetorazzo, 805, Box 2 Centro Incubador de Empresas, Distrito
                      Industrial São José do Rio Preto
                  </p>
                  </div>
                </div>
              </div>
            </div>
            

            <div className="contact-item">
              <div className="contact-icon">
                <img src={emailIcon} alt="Icone de e-mail" />
              </div>

              <div className="contact-right-side">
                <div className="contact-title">
                  <h3>E-mail</h3>
                </div>

                <div className="contact-info">
                  <p>
                   aga.inocat@gmail.com
                  </p>
                </div>
              </div>
            </div>

            <div className="contact-item">
              <div className="contact-icon">
                <img src={phoneIcon} alt="Icone de e-mail" />
              </div>

              <div className="contact-right-side">
                <div className="contact-title">
                  <h3>Telefone</h3>
                </div>

                <div className="contact-info">
                  <p>
                    (17) 99603-6362
                  </p>
                </div>
              </div>
            </div>

            <div className="contact-item">
              <div className="contact-icon">
                <img src={whatsappIcon} alt="Icone do Whatsapp" />
              </div>

              <div className="contact-right-side">
                <div className="contact-title">
                  <h3>Whatsapp</h3>
                </div>

                <div className="contact-info">
                  <p>
                    (17) 99603-6362
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </main>
      <Footer />
    </>
  )
}