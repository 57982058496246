import React from 'react';

import facebookIcon from '../../assets/icons/facebook.svg';
import linkedinIcon from '../../assets/icons/linkedin.svg';

import './styles.css';

function Footer() {
  return (
    <footer className="footer-container">
      <div className="content">
        <div className="slogan">
          <h1>InoCat</h1>
          <h2>
            Desenvolvimento de tecnologia catalítica para o processamento da biomassa e controle
            de emissões.
          </h2>

          <div className="slogan-icons">
            <div className="icon-box">
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={facebookIcon} alt="Facebook" />
              </a>
            </div>
            <div className="icon-box">
              <a
                href="https://linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkedinIcon} alt="LinkedIn" />
              </a>
            </div>
          </div>
        </div>

        <div className="about">
          <div className="links">
            <ul>
              <li className="list-title">Links</li>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/inocat">InoCat</a>
              </li>
              <li>
                <a href="/servicos">Serviços</a>
              </li>
              <li>
                <a href="/produtos">Produtos</a>
              </li>
              <li>
                <a href="/contato">Contato</a>
              </li>
            </ul>
          </div>

          <div className="contact">
            <ul>
              <li className="list-title">Contato</li>
              <li>
                <h3>Endereço</h3>
                <p>Avenida João Batista Vetorazzo, 805, Box 2</p>
                <p>Centro Incubador de Empresas</p>
                <p>Distrito Industrial - São José do Rio Preto, SP</p>
              </li>
              <li>
                <h3>E-mail</h3>
                <a href="mailto:aga.inocat@gmail.com">aga.inocat@gmail.com</a>
              </li>
              <li>
                <h3>Telefone</h3>
                <p>(17) 99603-6362</p>
              </li>
              <li>
                <h3>WhatsApp</h3>
                <p>(17) 99603-6362</p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="disclaimer">
        <p>Copyright &copy; 2020 AGA InoCat | Desenvolvido com &#10084; pela</p>
        <a href="http://estudioddr.dev">DDRdev</a>
      </div>
    </footer>
  );
}

export default Footer;
