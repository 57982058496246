import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Home from './pages/Home';
import Inocat from './pages/Inocat';
import Servicos from './pages/Servicos';
import Contato from './pages/Contato';
import Produtos from './pages/Produtos';

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/inocat" component={Inocat} />
      <Route path="/servicos" component={Servicos} />
      <Route path="/contato" component={Contato} />
      <Route path="/produtos" component={Produtos} />
    </Switch>
  </BrowserRouter>
);

export default Routes;