import React from 'react';

import "./styles.css";

export default function Title({subtitle, title}) {
  return (
    <>
      <div id="title-container">
        <h3>{subtitle}</h3>
        <h1>{title}</h1>
        <div />
      </div>
    </>
  )
}