import React from 'react';

import { FaLinkedin, FaAddressBook} from 'react-icons/fa'

import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import BigCard from '../../components/BigCard';
import Title from '../../components/Title';

import flasksIcon from '../../assets/icons/flasks.svg';
import greenIcon from '../../assets/icons/green.svg';
import plantIcon from '../../assets/icons/plant.svg';
import flagIcon from '../../assets/icons/flag.svg';
import eyeIcon from '../../assets/icons/eye-recognition.svg';
import diamondIcon from '../../assets/icons/diamond.svg';

import cnpqLogo from '../../assets/images/logo-cnpq.png';
import fapespLogo from '../../assets/images/logo-fapesp.png';
import parqueTecnologicoLogo from '../../assets/images/logo-parque-tecnologico.png';

import avatar1 from '../../assets/images/inocat/avatar1.jpg';
import avatar2 from '../../assets/images/inocat/avatar2.jpg';
import avatar3 from '../../assets/images/inocat/avatar3.jpg';


import './styles.css';

function Inocat() {
  return (
    <>
      <Navbar />

      <main>
        <div className="our-company">
          <Title subtitle="SOBRE A" title="EMPRESA" />

          <p>
            A InoCat, fundada em 2016, surgiu da ideia de transformar o conhecimento das áreas
            de física e química em produtos e processos inovadores. A empresa está localizada no
            complexo do Parque Tecnológico – São José do Rio Preto. Com status de empresa
            pesquisa “spin-off acadêmica”, a InoCat foi constituída com foco em Pesquisa,
            Desenvolvimento e Inovação (PD&I) e dispõe de uma moderna infraestrutura
            laboratorial, voltada para o desenvolvimento e a produção de novos catalisadores
            inorgânicos de aplicação industrial.
          </p>

          <p>
            A empresa tem se diferenciado nesse setor, principalmente quebrando padrões do
            mercado atual e construindo novos pilares competitivos com desenvolvimento de
            materiais eficientes de excelente custo-benefício. Esses novos catalisadores têm sido
            aplicados em diferentes setores industriais, tais como: processos de química fina,
            conversão da biomassa e controle de emissões.
          </p>
        </div>

        <div className="pillars">
          <div className="pillar">
            <div className="circle">
              <img src={flasksIcon} alt="Pesquisa" />
            </div>
            <div className="pillar-description">
              <h3>Pesquisa</h3>
              <p>
                A InoCat investe pesado em pesquisa, desenvolvimento e melhoria de processos
                catalíticos estratégicos para atender as necessidades de seus clientes.
              </p>
            </div>
          </div>

          <div className="pillar">
            <div className="circle">
              <img src={greenIcon} alt="Sustentabilidade" />
            </div>
            <div className="pillar-description">
              <h3>Sustentabilidade</h3>
              <p>
                A InoCat desenvolve catalisadores sustentáveis reaproveitando resíduos da própria
                indústria e utilizando-os para processamento da biomassa e no controle de emissões
                veiculares.
              </p>
            </div>
          </div>

          <div className="pillar">
            <div className="circle">
              <img src={plantIcon} alt="Inovação" />
            </div>
            <div className="pillar-description">
              <h3>Inovação</h3>
              <p>
                Investimento constante em PD&I é o elo vital para aproximar a InoCat de seus clientes,
                promovendo inovações tecnológicas no setor.
              </p>
            </div>
          </div>
        </div>

        <div className="values">
          <h2>MISSÃO, VISÃO E VALORES</h2>
          <h4>
            Missão, visão e valores são fundamentais para alcançar resultados
          </h4>

          <div className="values-container">
            <BigCard
              icon={flagIcon}
              title="MISSÃO"
              text="Ser referência no desenvolvimento de catalisadores usados no controle de emissões e
              novos processos catalíticos sustentáveis que possam fazer a diferença no mercado."
            />
            <BigCard
              icon={eyeIcon}
              title="VISÃO"
              text="Fazer uso do conhecimento gerado pela respeitada equipe de PD&I, levando tecnologia,
              inovação e competividade para os “quatro cantos” do país."
            />
            <BigCard
              icon={diamondIcon}
              title="VALORES"
              text="A base construtiva da empresa foi fundamentada em valores primordiais, como a ética
              e a sustentabilidade. A InoCat se diferencia no mercado por aplicar ciência e inovação
              no desenvolvimento de novas tecnologias catalíticas mais eficientes. A empresa
              também prioriza a inovação e a transferência de conhecimento para que de fato
              benefícios chegue à sociedade, garantindo assim uma estrutura empresarial sólida,
              bem-sucedida e lucrativa."
              scrollable
            />
          </div>
        </div>

        <div className="our-team">
          <h2>EQUIPE</h2>
          <h4>Conheça os pesquisadores da empresa InoCat</h4>

          <div className="team-container">
            <div className="member-container">
              <img src={avatar1} alt="Alex Silva" />

              <h2>Alex Silva</h2>
              <span></span>

              <h5>Co-fundador</h5>
              <p>
                Químico Industrial formado pela USP e doutor em biofísica
                molecular (UNESP).
              </p>

              <div className="social-container">
                <a href="https://www.linkedin.com/in/alex-silva-9a4b8a38/" className="social-link">
                  <FaLinkedin size="25px" />
                  LinkedIn
                </a>

                <a href="http://lattes.cnpq.br/2169449414841940" className="social-link">
                  <FaAddressBook size="25px" />
                  Lattes
                </a>
              </div>
            </div>

            <div className="member-container">
              <img src={avatar2} alt="Adriano de Vasconcellos" />

              <h2>Adriano de Vasconcellos</h2>
              <span></span>

              <h5>Co-fundador</h5>
              <p>
                Químico Ambiental formado pela UNESP e doutor em Microbiologia
                (UNESP).
              </p>

              <div className="social-container">
                <a href="https://br.linkedin.com/in/adriano-vasconcellos-144522ab" className="social-link">
                  <FaLinkedin size="25px" />
                  LinkedIn
                </a>

                <a href="http://lattes.cnpq.br/2010097786381172" className="social-link">
                  <FaAddressBook size="25px" />
                  Lattes
                </a>
              </div>
            </div>

            <div className="member-container">
              <img src={avatar3} alt="José Geraldo Nery" />

              <h2>José Geraldo Nery</h2>
              <span></span>

              <h5>Conselho Técnico-Científico</h5>
              <p>
                Químico industrial formado pela USP e doutor em Físico-Química
                (USP).
              </p>

              <div className="social-container">
                <a href="https://linkedin.com" className="social-link">
                  <FaLinkedin size="25px" />
                  LinkedIn
                </a>

                <a href="http://lattes.cnpq.br/8116645697040838" className="social-link">
                  <FaAddressBook size="25px" />
                  Lattes
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="our-partners">
          <h2>APOIADORES</h2>
          <h4>Conheça os principais apoiadores da empresa</h4>

          <div className="partners-container">
            <img src={cnpqLogo} alt="CNPQ" />
            <img src={fapespLogo} alt="FAPESP" />
            <img src={parqueTecnologicoLogo} alt="Parque Tecnológico" />
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
}

export default Inocat;
