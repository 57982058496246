import React from 'react';

import "./styles.css"

export default function Card({image, alt, title, content}) {
  return (
    <div id="card-container">
      <img src={image} alt={alt}/>
      <h1>{title}</h1>
      <p>{content}</p>
    </div>
  )
}