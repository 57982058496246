import React from 'react';

import "./styles.css";

export default function Button({ route, text }) {
  return (
    <a href={route} className="link-container">
      {text}
    </a>
  )
}