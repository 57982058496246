import React, { useState } from 'react';
import Carousel from 'react-slick';

import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer'

import Title from '../../components/Title';

import "./styles.css";

import fau1 from "../../assets/images/aplicacoes/fau/fau1.jpg";
import fau2 from "../../assets/images/aplicacoes/fau/fau2.png";

import lta1 from "../../assets/images/aplicacoes/lta/lta1.jpg";
import lta2 from "../../assets/images/aplicacoes/lta/lta2.png";

import beta1 from "../../assets/images/aplicacoes/beta/beta1.jpg";
import beta2 from "../../assets/images/aplicacoes/beta/beta2.png";

import zsm1 from "../../assets/images/aplicacoes/zsm/zsm1.jpg";
import zsm2 from "../../assets/images/aplicacoes/zsm/zsm2.jpg";
import zsm3 from "../../assets/images/aplicacoes/zsm/zsm3.jpg";

import gis1 from "../../assets/images/aplicacoes/gis/gis1.jpg";
import gis2 from "../../assets/images/aplicacoes/gis/gis2.jpg";

import nano_lta1 from '../../assets/images/aplicacoes/nanozeolitas/nano_lta/lta1.jpg'
import nano_lta2 from '../../assets/images/aplicacoes/nanozeolitas/nano_lta/lta2.png'

import nano_fau1 from '../../assets/images/aplicacoes/nanozeolitas/nano_fau/nano_fau1.jpg';
import nano_fau2 from '../../assets/images/aplicacoes/nanozeolitas/nano_fau/nano_fau2.png';

import bea1 from '../../assets/images/aplicacoes/nanozeolitas/bea/bea1.jpg';
import bea2 from '../../assets/images/aplicacoes/nanozeolitas/bea/bea2.png';

import nano_gis1 from '../../assets/images/aplicacoes/nanozeolitas/nano_gis/nano_gis1.jpg';
import nano_gis2 from '../../assets/images/aplicacoes/nanozeolitas/nano_gis/nano_gis2.png';

import ts11 from '../../assets/images/aplicacoes/nanozeolitas/ts1/ts11.jpg';
import ts12 from '../../assets/images/aplicacoes/nanozeolitas/ts1/ts12.png';

import vanado1 from '../../assets/images/aplicacoes/opts/vanado/vanado1.png';
import vanado2 from '../../assets/images/aplicacoes/opts/vanado/vanado2.png';

import zirco1 from '../../assets/images/aplicacoes/opts/zirco/zirco1.png';
import zirco2 from '../../assets/images/aplicacoes/opts/zirco/zirco2.png';

import germa1 from '../../assets/images/aplicacoes/opts/germa/germa1.png';
import germa2 from '../../assets/images/aplicacoes/opts/germa/germa2.png';

import niobo1 from '../../assets/images/aplicacoes/opts/niobo/niobo1.png';
import niobo2 from '../../assets/images/aplicacoes/opts/niobo/niobo2.png';

import oxido1 from '../../assets/images/aplicacoes/oxidos/oxido1.jpg';
import oxido2 from '../../assets/images/aplicacoes/oxidos/oxido2.png';

import peneira1 from '../../assets/images/aplicacoes/peneiras.png';

import novo from '../../assets/images/aplicacoes/novo.png';

export default function Produtos() {

  const zeolitasOptions = [
    {
      id: 1,
      name: 'Zeólita Faujasita (FAU)',
      images: [fau1, fau2],
    },
    {
      id: 2,
      name: 'Zeólita LTA',
      images: [lta1, lta2],
    },
    {
      id: 3,
      name: 'Zeólita Beta',
      images: [beta1, beta2],
    },
    {
      id: 4,
      name: 'ZSM – 5',
      images: [zsm1, zsm2, zsm3],
    },
    {
      id: 5,
      name: 'Zeólita Gismondina',
      images: [gis1, gis2],
    }
  ]

  const nanozeolitasOptions = [
    {
      id: 1,
      name: 'LTA (Nano LTA)',
      images: [nano_lta1, nano_lta2],
    },
    {
      id: 2,
      name: 'Faujasita (Nano FAU)',
      images: [nano_fau1, nano_fau2],
    },
    {
      id: 3,
      name: 'Beta (Nano -BEA)',
      images: [bea1, bea2],
    },
    {
      id: 4,
      name: 'Gismondina (Nano GIS)',
      images: [nano_gis1, nano_gis2],
    },
    {
      id: 5,
      name: 'TS-1',
      images: [ts11, ts12],
    }
  ]

  const optOptions = [
    {
      id: 1,
      name: 'Vanadosilicato',
      images: [vanado1, vanado2],
    },
    {
      id: 2,
      name: 'Zirconosilicato',
      images: [zirco1, zirco2],
    },
    {
      id: 3,
      name: 'Germanosilicatos',
      images: [germa1, germa2],
    },
    {
      id: 4,
      name: 'Niobosilicatos',
      images: [niobo1, niobo2],
    },
  ]

  const novosOptions = [
    {
      id: 1,
      name: 'Novos Materiais',
      images: [novo],
      description: "A equipe de PD&I da empresa busca estar sempre à frente quando se trata de inovação,"+
                    "nesse aspecto vários materiais foram desenvolvidos. Novas rotas sintéticas permitiram "+
                    "a obtenção de peneiras moleculares usando rejeitos sólidos oriundos das indústrias de"+
                    "metalurgia, usinagem e fundição. A versatilidade desses materiais para aplicações"+
                    " industriais está relacionada às propriedades físico-químicas, tais como: resistência"+
                    " térmica e mecânica, além das características ácidas, básicas e oxi-redutoras."
    },
  ]

  const oxidosOptions = [
    {
      id: 1,
      name: 'Óxidos Metálicos',
      images: [oxido1, oxido2],
    } 
  ]

  const peneiraOptions = [
    {
      id: 1,
      name: 'Peneiras Moleculares Modificadas',
      images: [peneira1],
      description: "Os pesquisadores da InoCat produzem e desenvolvem materiais modificados fisico-quimicamente como zeólitas e nanozeólitas com características catalíticas de acordo com as necessidades e aplicações a serem empregadas. As zeólitas e nanozeólitas modificadas apresentam características como a hidrofilicidade, hidrofobicidade, basicidade, acidez e a oxi-redução."
    }
  ]

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const [products, setProducts] = useState(zeolitasOptions);
  const [carousel, setCarousel] = useState(products[0].images)
  
  return (
    <>
      <Navbar />

      <div id="aplicacoes-container">
        <Title subtitle="PRINCIPAIS" title="CATALISADORES E ADSORVENTES" />

        <p>
          A InoCat é uma empresa especializada em sínteses de uma vasta gama de materiais
          zeolíticos, peneiras moleculares, nanomateriais e óxidos metálicos que podem ser
          aplicados como catalisadores e adsorventes industriais. A equipe de especialistas tem
          muitos anos de experiência na síntese, produção e desenvolvimento de projetos nesse
          setor.
        </p>

        <div id="produtos-container">
          <div id="produtos-leftside">
            <div id="produto-header" className="rounded-top">
              <strong>Todos os produtos</strong>
            </div>

            <div
              className="produto-item"
              onClick={event => {
                setProducts(zeolitasOptions);
                setCarousel(zeolitasOptions[0].images)
              }}
            >
              <span>Zeólitas</span>
            </div>

            <div
              className="produto-item"
              onClick={event => {
                setProducts(nanozeolitasOptions);
                setCarousel(nanozeolitasOptions[0].images)
              }}
            >
              <span>Nanozeólitas</span>
            </div>

            <div className="produto-item" onClick={event => {
              setProducts(optOptions);
              setCarousel(optOptions[0].images)
            }}>
              <span>OPT Materials</span>
            </div>

            <div
              className="produto-item"
              onClick={event => {
                setProducts(oxidosOptions);
                setCarousel(oxidosOptions[0].images)
              }}
            >
              <span>Óxidos Metálicos</span>
            </div>

            <div
              className="produto-item"
              onClick={event => {
                setProducts(peneiraOptions);
                setCarousel(peneiraOptions[0].images)
              }}
            >
              <span>Peneiras Moleculares Modificadas</span>
            </div>

            <div
              className="produto-item"
              onClick={event => {
                setProducts(novosOptions);
                setCarousel(novosOptions[0].images)
              }}
            >
              <span>Novos Materiais</span>
            </div>
          </div>

          <div id="produtos-carousel">
            <Carousel {...settings}>
              {carousel.map(product => (
                <div className="produto-carousel-item">
                  <img src={product} alt="zeolita" />
                </div>
              ))}
            </Carousel>
          </div>

          <div id="produtos-rightside">
            <div id="produto-header" className="rounded-top">
              <strong>Produtos</strong>
            </div>

            {products.length === 0 ? (
              <div className="produto-item rounded-bottom">
                <span>Sem produtos cadastrados</span>
              </div>
            ) : null}

            {products.map(product => (
              <div className="produto-item">
                <span onClick={event => setCarousel(product.images)}>{product.name}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      
      { products[0].description ? (
        <div id="product-description">
          <p>{products[0].description}</p>
        </div>
      ) : null}

      <Footer />
    </>
  );
}
