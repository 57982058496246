import React from 'react';
import Carousel from 'react-slick';

import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";

import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import Button from '../../components/Button'
import Title from '../../components/Title'

import logo from '../../assets/images/logo.png';
import img1 from '../../assets/images/3-way.png';
import img2 from '../../assets/images/carouse-img2.png';
import img3 from '../../assets/images/img3.jpg'
import img4 from '../../assets/images/emissoes-veiculares.jpg'

import like from '../../assets/icons/like.svg';
import inovation from '../../assets/icons/inovation.svg';
import diamond from '../../assets/icons/diamond.svg';

import equipaments_img from '../../assets/images/equipamentos.png';

import './styles.css';

export default function Home() {

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <>
      <Navbar />
      <div className="container">
        <div id="home-mobile">
          <img src={logo} alt="logo " />

          <h5>
            Desenvolvimento de tecnologia catalítica para o processamento da biomassa
          </h5>

          <Button route="/inocat" text="SAIBA MAIS" />
        </div>

        <div id="carousel-container">
          <Carousel {...settings}>
            <div className="carousel-item">
              <div className="carousel-left-side">
                <img src={logo} alt="logo " />
                <h5>
                  Desenvolvimento de tecnologia catalítica para o controle de emissões veiculares
                </h5>

                <Button route="/inocat" text="SAIBA MAIS" />
              </div>

              <div className="image">
                <img src={img1} alt="slide1" />
              </div>
            </div>

            <div className="carousel-item">
              <div className="carousel-left-side">
                <img src={logo} alt="logo " />
                <h5>
                  Desenvolvimento de tecnologia catalítica para o processamento da biomassa
                </h5>


                <Button route="/inocat" text="SAIBA MAIS" />
              </div>

              <div className="image">
                <img src={img2} alt="slide1" />
              </div>
            </div>
          </Carousel>
        </div>
      </div>

      <div id="main-content">
        <div id="card-home">
          <div className="card-home-wrapper">
            <img src={like} alt="card-icon" />

            <h4>Confiabilidade</h4>

            <p>
              A InoCat atende todos padrões de confiabilidade, garantindo o sigilo dos seus serviços
              e um suporte técnico de alto nível.
            </p>
          </div>

          <div className="card-home-wrapper center">
            <img src={inovation} alt="card-icon" />

            <h4>Inovação</h4>

            <p>
              Investimento constante em PD&I é o elo vital para aproximar a InoCat de seus clientes,
              promovendo inovações tecnológicas no setor.
            </p>
          </div>

          <div className="card-home-wrapper">
            <img src={diamond} alt="card-icon" />

            <h4>Qualidade</h4>

            <p>
              A InoCat atende padrões de excelência com melhoria contínua, garantindo a qualidade
              dos seus serviços e produtos.
            </p>
          </div>
        </div>

        <div id="equipments-section">
          <div className="left-side">
            <img src={equipaments_img} alt="equipaments" />
          </div>

          <div className="right-side">
            <div className="paragraph-wrapper">
              <Title title="QUALIDADE É TUDO!" />

              <p>
                A InoCat utiliza o que há de mais avançado em equipamentos e reatores para garantir
                a otimização dos processos, por meio da melhor combinação de tecnologia, qualidade
                e preço, sempre com inovações endereçadas as necessidades reais do mercado.
              </p>

              <Button route="/servicos" text="SAIBA MAIS" />
            </div>
          </div>
        </div>

        <div id="emission-section">
          <div className="left-side">
            <div className="paragraph-wrapper">
              <Title subtitle="CONTROLE DE" title="EMISSÕES VEICULARES" />

              <p>
                Os conversores catalíticos de três vias desenvolvidos pela InoCat são altamente
                eficientes no controle da poluição proveniente da frota veicular e a sua principal
                vantagem competitiva é o baixo custo, visto que os metais nobres (ródio, paládio e
                platina) terão um papel secundário nessa nova tecnologia.
              </p>

              <Button route="/produtos" text="SAIBA MAIS" />
            </div>
          </div>

          <div className="right-side">
            <img src={img4} alt="emission-control" />
          </div>
        </div>

        <div id="gliceroquimica-section">
          <div className="left-side">
            <img src={img3} alt="gliceroquimica" />
          </div>

          <div className="right-side">
            <div className="paragraph-wrapper">
              <Title title="GLICEROQUÍMICA" />

              <p>
                A glicerina (subproduto da indústria do biodiesel) se tornou uma molécula chave para a
                obtenção de novos produtos com alto valor de mercado. O processo inovador
                desenvolvido por pesquisadores da InoCat envolve o uso de catalisadores
                heterogêneos inéditos, os quais podem ser facilmente recuperados e reutilizados por
                vários ciclos, reduzindo custos e desperdícios no processo.
              </p>

              <Button route="/servicos" text="SAIBA MAIS" />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
} 