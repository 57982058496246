import React from 'react';

import './styles.css';

function BigCard({ icon, title, text, scrollable }) {
  return (
    <div className="card-container">
      <div className="left-side">
        <img src={icon} alt={title} />
        <h1>{title}</h1>
      </div>

      <div className={scrollable ? "right-side scrollable" : "right-side"}>
        <p>{text}</p>
      </div>
    </div>
  );
}

export default BigCard;
