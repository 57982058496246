import React from 'react';

import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';

import moleculeIcon from '../../assets/icons/molecular.svg';
import powderIcon from '../../assets/icons/powder.svg';
import gasIcon from '../../assets/icons/Gas.svg';

import Title from '../../components/Title'
import Card from '../../components/Card'

import './styles.css';

function Servicos() {

  return (
    <>
      <Navbar />
      <main>

        <Title subtitle="PRINCIPAIS" title="SERVIÇOS" />

        <div className="our-services">
          <p>
            A empresa InoCat® oferece aos seus clientes serviços analíticos para a identificação e
            quantificação de compostos orgânicos e inorgânicos em fase sólida ou líquida. Dentro
            do rol de técnicas analíticas disponibilizadas pela InoCat, tem-se:
          </p>

          <div className="services-container">
            <Card
              image={moleculeIcon}
              alt="molécula"
              title="FT-IR"
              content="Espectroscopia de Infravermelho com transformada de 
              Fourier – Técnica usada para caracterização de compostos orgânicos
              e inorgânicos em estado sólido."
            />

            <Card
              image={powderIcon}
              alt="molécula"
              title="DRX"
              content="Difração de Raios X – Técnica aplicada para caracterização 
              estrutural de materiais sólidos na forma pó e policristalina"
            />

            <Card
              image={gasIcon}
              alt="molécula"
              title="CG"
              content="Cromatografia Gasosa – Técnica analítica utilizada para a 
              identificação e quantificação de substâncias orgânicas líquidas com 
              pontos de ebulição abaixo de 250ºC."
            />
          </div>
      
          <p>
            As técnicas analíticas disponibilizadas pela InoCat atendem às pesquisas realizadas no
            meio acadêmico, assim como as rotinas desenvolvidas em indústrias do setor químico,
            farmacêutico e biomédico.
          </p>

      </div>

        <div className="consulting">
          <h2>CONSULTORIA NA ÁREA DE CATÁLISE</h2>
          <h4>Desenvolvimento de novas rotas sintéticas</h4>
          
          <p>
            A InoCat oferece aos seus clientes serviços de consultoria, em escala laboratorial,
            voltados para a implementação de novas rotas tecnológias em diferentes setores da
            indústria química.
          </p>

          <div className="other-services">
            <div className="card-services">
              <h1>Reaproveitamento de resíduos sólidos industriais</h1>
            </div>
            
            <div className="card-services">
              <h1>Processamento e aproveitamento da biomassa</h1>
            </div>

            <div className="card-services">
              <h1>Biocombustíveis</h1>
            </div>

            <div className="card-services">
              <h1>Oleoquímico</h1>
            </div>
          </div>

        </div>
      </main>

      <Footer />
    </>
  );
}

export default Servicos;
