import React from 'react';

import Routes from './routes';

import Navbar from './components/Navbar';

import "./global.css";

const App = () => (
  <>
    <Routes>
      <Navbar />
    </Routes>
  </>
);

export default App;
